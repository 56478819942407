<template>
  <el-container class="web-layout-container">
    <el-header class="header-wrapper">
      <el-container>
        <el-aside class="logo-box">
          <img :src="headuserlogo" />
        </el-aside>
        <el-main class="header-main-box">
          <el-menu :default-active="navActiveKey" mode="horizontal" :router="true">
            <template v-if="!isHideTopMenuLabel">
              <el-menu-item index="home"><i class="el-icon-home"></i>{{ $t('webLayout.navHome') }}</el-menu-item>
              <el-menu-item index="inspection_monitoring" v-if="$store.getters.menuFuntionAuth('MONITOR')"><i class="el-icon-inspection"></i>{{ $t('webLayout.navInspection') }}</el-menu-item>
              <el-menu-item :index="null" v-else @click="checkVersion"><i class="el-icon-inspection"></i>{{ $t('webLayout.navInspection') }}</el-menu-item>
              <el-menu-item index="safety_incident" v-if="$store.getters.menuFuntionAuth('SAFETY')" :class="{ safety_flashing: safetyIncidentFlashing }"><i class="el-icon-security-incidents"></i>{{ $t('webLayout.navSafetyIncident') }} </el-menu-item>
              <el-menu-item :index="null" v-else @click="checkVersion"><i class="el-icon-security-incidents"></i>{{ $t('webLayout.navSafetyIncident') }}</el-menu-item>
              <el-menu-item index="property_list"><i class="el-icon-property"></i>{{ $t('webLayout.navProperty') }}</el-menu-item>
              <el-submenu index="system" popper-class="top-submenu-popper">
                <template slot="title"><i class="el-icon-setting-solid"></i>{{ $t('webLayout.navSystem') }}</template>
                <el-menu-item index="sso">{{ $t('webLayout.navSSO') }}</el-menu-item>
                <el-menu-item index="common_reason_list">{{ $t('webLayout.navCommonReasonManagement') }}</el-menu-item>
                <el-menu-item index="sla">{{ $t('webLayout.navSla') }}</el-menu-item>
                <el-menu-item index="visitor_question_tmpl_management">{{ $t('webLayout.navVisitorQuestionTmpl') }}</el-menu-item>
                <el-menu-item index="class_management">{{ $t('webLayout.classManagement') }}</el-menu-item>
              </el-submenu>
              <el-menu-item index="notice"><i class="el-icon-notification"></i>{{ $t('webLayout.navNotification') }}</el-menu-item>
              <el-menu-item>
                <el-popover popper-class="app-popper" placement="bottom" width="600" trigger="hover">
                  <div class="float-box">
                    <div class="qr_img_group">
                      <div class="qr_img_box">
                        <img class="qr_img" src="../assets/android.png" />
                      </div>
                      <div class="qr_label">
                        <i class="iconfont icon-android"></i>
                        <span class="qr_label_content">{{ $t('webLayout.androidDownload') }}</span>
                      </div>
                    </div>
                    <div class="qr_img_group">
                      <div class="qr_img_box">
                        <img class="qr_img" src="../assets/ios.png" />
                      </div>
                      <div class="qr_label">
                        <i class="iconfont icon-ios"></i>
                        <span class="qr_label_content">{{ $t('webLayout.iosDownload') }}</span>
                      </div>
                    </div>
                  </div>
                  <span slot="reference"><i class="el-icon-app"></i>{{ $t('webLayout.navApp') }}</span>
                </el-popover>
              </el-menu-item>
            </template>
            <template v-else>
              <el-menu-item index="home">
                <el-tooltip effect="light" :content="$t('webLayout.navHome')" placement="top">
                  <i class="el-icon-home"></i>
                </el-tooltip>
              </el-menu-item>
              <el-menu-item index="inspection_monitoring" v-if="$store.getters.menuFuntionAuth('MONITOR')">
                <el-tooltip effect="light" :content="$t('webLayout.navInspection')" placement="top">
                  <i class="el-icon-inspection"></i>
                </el-tooltip>
              </el-menu-item>
              <el-menu-item :index="null" v-else @click="checkVersion">
                <el-tooltip effect="light" :content="$t('webLayout.navInspection')" placement="top">
                  <i class="el-icon-inspection"></i>
                </el-tooltip>
              </el-menu-item>
              <el-menu-item index="safety_incident" v-if="$store.getters.menuFuntionAuth('SAFETY')" :class="{ safety_flashing: safetyIncidentFlashing }">
                <el-tooltip effect="light" :content="$t('webLayout.navSafetyIncident')" placement="top">
                  <i class="el-icon-security-incidents"></i>
                </el-tooltip>
              </el-menu-item>
              <el-menu-item :index="null" v-else @click="checkVersion">
                <el-tooltip effect="light" :content="$t('webLayout.navSafetyIncident')" placement="top">
                  <i class="el-icon-security-incidents"></i>
                </el-tooltip>
              </el-menu-item>
              <el-menu-item index="property_list">
                <el-tooltip effect="light" :content="$t('webLayout.navProperty')" placement="top">
                  <i class="el-icon-property"></i>
                </el-tooltip>
              </el-menu-item>
              <el-submenu index="system" popper-class="top-submenu-popper">
                <template slot="title">
                  <el-tooltip effect="light" :content="$t('webLayout.navSystem')" placement="top">
                    <i class="el-icon-setting-solid"></i>
                  </el-tooltip>
                </template>
                <el-menu-item index="sso">{{ $t('webLayout.navSSO') }}</el-menu-item>
                <el-menu-item index="common_reason_list">{{ $t('webLayout.navCommonReasonManagement') }}</el-menu-item>
                <el-menu-item index="sla">{{ $t('webLayout.navSla') }}</el-menu-item>
                <el-menu-item index="visitor_question_tmpl_management">{{ $t('webLayout.navVisitorQuestionTmpl') }}</el-menu-item>
                <el-menu-item index="class_management">{{ $t('webLayout.classManagement') }}</el-menu-item>
              </el-submenu>
              <el-menu-item index="notice">
                <el-tooltip effect="light" :content="$t('webLayout.navNotification')" placement="top">
                  <i class="el-icon-notification"></i>
                </el-tooltip>
              </el-menu-item>
              <el-menu-item>
                <el-popover popper-class="app-popper" placement="bottom" width="600" trigger="hover">
                  <div class="float-box">
                    <div class="qr_img_group">
                      <div class="qr_img_box">
                        <img class="qr_img" src="../assets/android.png" />
                      </div>
                      <div class="qr_label">
                        <i class="iconfont icon-android"></i>
                        <span class="qr_label_content">{{ $t('webLayout.androidDownload') }}</span>
                      </div>
                    </div>
                    <div class="qr_img_group">
                      <div class="qr_img_box">
                        <img class="qr_img" src="../assets/ios.png" />
                      </div>
                      <div class="qr_label">
                        <i class="iconfont icon-ios"></i>
                        <span class="qr_label_content">{{ $t('webLayout.iosDownload') }}</span>
                      </div>
                    </div>
                  </div>
                  <span slot="reference">
                    <el-tooltip effect="light" :content="$t('webLayout.navApp')" placement="top">
                      <i class="el-icon-app"></i>
                    </el-tooltip>
                  </span>
                </el-popover>
              </el-menu-item>
            </template>
          </el-menu>

          <div class="personal-info-wrapper">
            <div class="name" :title="$store.getters.headerPedetail && $store.getters.headerPedetail.description">
              {{ $store.getters.headerPedetail && $store.getters.headerPedetail.description }}
            </div>
            <!-- headerPedetail.pelogo ||  -->
            <div class="head-sculpture"><img :src="$store.getters.headerPedetail && $store.getters.headerPedetail.pelogo ? $store.getters.headerPedetail.pelogo : headerDefaultLogo" /></div>
            <el-dropdown class="language-box" @command="switchLanguage">
              <span> {{ showLanguage }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown" class="lang-popper">
                <el-dropdown-item :key="langIndex" :command="langItem.value" v-for="(langItem, langIndex) of languageItemArr">{{ langItem.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popover v-model="userinfoPopperVisible" placement="bottom-end" trigger="manual" class="userinfo-wrapper" popper-class="userinfo-popper" ref="userinfoPopover">
              <template slot="reference">
                <div class="user-info-box" @click="userinfoPopperVisible = !userinfoPopperVisible">
                  <span class="phone-box">{{ headerDisplayname }}</span>
                  <span class="user-level">{{ showVersionName }}<i :class="['el-icon-arrow-down el-icon--right', { active: userinfoPopperVisible }]"></i></span>
                </div>
              </template>
              <div class="userinfo-more-wrapper">
                <div :class="['card_box', cardColor]" v-if="showVersionName && cardColor">
                  <div>
                    <span class="card_label is_bold">{{ showVersionName }}</span>
                    <span>{{ loclimit }} {{ $t('webLayout.locCountLabel') }}</span>
                  </div>
                  <div class="card_expire_date_box" v-if="headversion !== 'EXPERIENCE' && enddate">
                    <span>{{ $t('webLayout.cardExpireDateLabel') }}：</span>
                    <span>{{ enddate }}</span>
                  </div>
                </div>
                <div @click="goUserinfo">
                  <span><i class="el-icon-account"></i>{{ $t('webLayout.navAccount') }}</span>
                </div>
                <div @click="getOfficialWebsite">
                  <span><i class="el-icon-home"></i>{{ $t('webLayout.webSiteHome') }}</span>
                </div>
                <div class="release_history" @click="goToHistoryPage">
                  <span :class="{ active: !isHistoryStatus }"><i class="el-icon-history"></i>{{ $t('webLayout.releaseHistory') }}</span>
                </div>
                <div id="newbie_guide" v-if="isGuide" @click="openNewbieGuide">
                  <span><i class="el-icon-guide"></i>{{ $t('webLayout.showGuideName') }}</span>
                </div>
                <a :href="helpDocUrl" target="_blank" v-if="helpDocUrl">
                  <span><i class="el-icon-help-document"></i>{{ $t('webLayout.helpDocument') }}</span>
                </a>
                <div class="theme_box">
                  <span><i class="el-icon-color-set"></i>{{ $t('common.nightMode') }}</span>
                  <el-switch v-model="dark" @change="switchTheme"></el-switch>
                </div>
                <div v-show="!accountViewSetting.isdisplaycontact && $store.getters.lang == 'zh'">
                  <span><i class="el-icon-customer-service"></i>{{ $t('webLayout.onLineService') }}</span
                  ><span class="hotline-num">18320886254</span>
                </div>
                <div @click="loginOut">
                  <!-- <span class="iconify" data-icon="ic:round-logout"></span> -->
                  <span><i class="el-icon-logout"></i>{{ $t('webLayout.logOut') }}</span>
                </div>
              </div>
            </el-popover>
          </div>
        </el-main>
      </el-container>
    </el-header>
    <el-container>
      <el-aside width="auto" class="aside-menu-wrapper">
        <div @click="shrinkEvent" class="shrink-button" :class="{ 'is-menu-collapse': !isMenuCollapse }">
          <!-- {{$t('webLayout.collapseMenu')}} -->
          <i class="el-icon-prevPage icon-box"></i>
        </div>
        <el-scrollbar style="height: calc(100vh - 60px)">
          <el-menu :default-active="menuActiveKey" :default-openeds="menuOpeneds" class="menu-box" :collapse="isMenuCollapse">
            <el-submenu index="propertyEntity">
              <template slot="title">
                <i class="el-icon-property-entity"></i>
                <span>{{ $t('webLayout.propertyEntity') }}</span>
              </template>
              <el-menu-item index="basicinfo" @click="jumpView('/basicinfo')">{{ $t('webLayout.entityDetail') }}</el-menu-item>
              <el-menu-item id="account_menu" index="account" @click="jumpView('/account')">
                <span id="page-account">{{ $t('webLayout.userAccounts') }}</span>
              </el-menu-item>
              <!-- 安全卫士 -->
              <el-menu-item index="securityRules" v-if="$store.getters.menuFuntionAuth('SAFETY')" @click="jumpView('/securityRules')">{{ $t('webLayout.safetyGuard') }}</el-menu-item>
              <el-menu-item index="securityRules" v-else @click="checkVersion">{{ $t('webLayout.safetyGuard') }}</el-menu-item>
              <el-menu-item index="authmanagement" @click="jumpView('/authmanagement')">{{ $t('webLayout.authorizationManagement') }}</el-menu-item>

              <el-menu-item index="group" @click="jumpView('/group')">{{ $t('webLayout.workGroups') }}</el-menu-item>
              <el-menu-item index="authority" v-if="$store.getters.menuFuntionAuth('AUTH_GROUP')" @click="jumpView('/authority')">{{ $t('webLayout.accessGroups') }}</el-menu-item>
              <el-menu-item index="authority" v-else @click="checkVersion">{{ $t('webLayout.accessGroups') }}</el-menu-item>
              <el-menu-item id="location_menu" index="location" @click="jumpView('/location')">
                <span id="page-location">{{ $t('webLayout.inspectionPoints') }}</span>
              </el-menu-item>

              <el-menu-item index="holiday" @click="jumpView('/holiday')">{{ $t('webLayout.holidayManagement') }}</el-menu-item>
              <el-menu-item index="assets" @click="jumpView('/assets')">{{ $t('webLayout.assetLedger') }}</el-menu-item>
              <el-menu-item index="sensor" @click="jumpView('/sensor')">{{ $t('webLayout.iotSensors') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="InspectionPlan">
              <template slot="title">
                <i class="el-icon-inspection-plan"></i>
                <span>{{ $t('webLayout.inspectionPlan') }}</span>
              </template>
              <el-menu-item id="insp_plan_menu" index="create" @click="jumpView('/create')"
                ><span id="page-create">{{ $t('webLayout.createInspectionPlan') }}</span></el-menu-item
              >
              <el-menu-item id="insp_plan_list" index="task" @click="jumpView('/task')">{{ $t('webLayout.inspectionPlanManagement') }}</el-menu-item>
              <el-menu-item index="commonlist" @click="jumpView('/commonlist')">{{ $t('webLayout.sopManagement') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="WorkOrderTemplate" v-if="(($store.getters.isOperate || $store.getters.ispemgr) && $store.getters.menuFuntionAuth('WO_TEMP')) || !$store.getters.menuFuntionAuth('WO_TEMP')">
              <template slot="title">
                <i class="el-icon-work-order-template"></i>
                <span>{{ $t('webLayout.workOrderTemplate') }}</span>
              </template>
              <el-menu-item index="wotemplatelist" @click="jumpView('/wotemplatelist')" v-if="($store.getters.isOperate || $store.getters.ispemgr) && $store.getters.menuFuntionAuth('WO_TEMP')">{{ $t('webLayout.workOrderTemplateManagement') }}</el-menu-item>
              <el-menu-item index="wotemplatelist" @click="checkVersion" v-else>{{ $t('webLayout.workOrderTemplateManagement') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="PunchInTemplate" v-if="(($store.getters.isOperate || $store.getters.ispemgr) && $store.getters.menuFuntionAuth('PUNCHIN')) || !$store.getters.menuFuntionAuth('PUNCHIN')">
              <template slot="title">
                <i class="el-icon-punch-in-template"></i>
                <span>{{ $t('webLayout.punchInTemplate') }}</span>
              </template>
              <el-menu-item index="signintemplatelist" @click="jumpView('/signintemplatelist')" v-if="($store.getters.isOperate || $store.getters.ispemgr) && $store.getters.menuFuntionAuth('PUNCHIN')">{{ $t('webLayout.signInTemplateManagement') }}</el-menu-item>
              <el-menu-item index="signintemplatelist" @click="checkVersion" v-else>{{ $t('webLayout.signInTemplateManagement') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="MonitorTemplate">
              <template slot="title">
                <i class="el-icon-monitor-template"></i>
                <span>{{ $t('webLayout.monitorTemplate') }}</span>
              </template>
              <el-menu-item index="monitorlist" @click="jumpView('/monitorlist')" v-if="$store.getters.menuFuntionAuth('CM')">{{ $t('webLayout.monitorTemplateManagement') }}</el-menu-item>
              <el-menu-item index="monitorlist" @click="checkVersion" v-else>{{ $t('webLayout.monitorTemplateManagement') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="HistoricalRecords">
              <template slot="title">
                <i class="el-icon-historical-records"></i>
                <span>{{ $t('webLayout.historicalRecords') }}</span>
              </template>
              <el-menu-item index="rcwo" @click="jumpView('/rcwo')">{{ $t('webLayout.inspectionOrderRecords') }}</el-menu-item>

              <el-menu-item index="wolist" @click="jumpView('/wolist')" v-if="$store.getters.menuFuntionAuth('WO')">{{ $t('webLayout.workOrderRecords') }}</el-menu-item>
              <el-menu-item index="wolist" @click="checkVersion" v-else>{{ $t('webLayout.workOrderRecords') }}</el-menu-item>

              <el-menu-item index="signinlist" @click="jumpView('/signinlist')" v-if="$store.getters.menuFuntionAuth('PUNCHIN')">{{ $t('webLayout.signInRecord') }}</el-menu-item>
              <el-menu-item index="signinlist" @click="checkVersion" v-else>{{ $t('webLayout.signInRecord') }}</el-menu-item>

              <el-menu-item index="meterreadinglist" @click="jumpView('/meterreadinglist')" v-if="$store.getters.isOperate || $store.getters.ispemgr">{{ $t('webLayout.iotSensorList') }}</el-menu-item>
              <el-menu-item index="event" @click="jumpView('/event')">{{ $t('webLayout.incidentOrderRecords') }}</el-menu-item>
              <el-menu-item index="datalist" @click="jumpView('/datalist')">{{ $t('webLayout.dataMeterReadings') }}</el-menu-item>

              <el-menu-item index="rcalert" @click="jumpView('/rcalert')" v-if="$store.getters.menuFuntionAuth('ALERT')">{{ $t('webLayout.alertsHistory') }}</el-menu-item>
              <el-menu-item index="rcalert" @click="checkVersion" v-else>{{ $t('webLayout.alertsHistory') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="VisitorManagement">
              <!--            <el-submenu index="VisitorManagement" v-if="$store.getters.selfregister || $store.getters.isOperate">-->
              <template slot="title">
                <i class="el-icon-visitor-management"></i>
                <span>{{ $t('webLayout.visitorManagement') }} <i @click.stop="visitorQuestion" class="el-icon-question el-icon"></i></span>
              </template>
              <template v-if="$store.getters.selfregister || $store.getters.isOperate">
                <el-menu-item index="guest_qrcode" @click="jumpView('/guest_qrcode')" v-if="$store.getters.menuFuntionAuth('VISITOR')">
                  {{ $t('webLayout.receptionEntranceManagement') }}
                </el-menu-item>
                <el-menu-item index="guest_qrcode" @click="checkVersion" v-else>
                  {{ $t('webLayout.receptionEntranceManagement') }}
                </el-menu-item>
              </template>

              <el-menu-item index="guest_reserve" @click="jumpView('/guest_reserve')" v-if="$store.getters.menuFuntionAuth('VISITOR')">
                {{ $t('webLayout.visitorAppointments') }}
              </el-menu-item>
              <el-menu-item index="guest_reserve" @click="checkVersion" v-else>{{ $t('webLayout.visitorAppointments') }}</el-menu-item>

              <el-menu-item index="visithistory" @click="jumpView('/visithistory')" v-if="$store.getters.menuFuntionAuth('VISITOR')">{{ $t('webLayout.visitorVisitRecords') }}</el-menu-item>
              <el-menu-item index="visithistory" @click="checkVersion" v-else>{{ $t('webLayout.visitorVisitRecords') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="SupportingFunctions">
              <template slot="title">
                <i class="el-icon-supporting-functions"></i>
                <span>{{ $t('webLayout.supportingFunctions') }}</span>
              </template>
              <el-menu-item index="worktablelog" @click="jumpView('/worktablelog')" v-if="$store.getters.menuFuntionAuth('JOB_LOG')">{{ $t('webLayout.dutyLogbook') }}</el-menu-item>
              <el-menu-item index="worktablelog" @click="checkVersion" v-else>{{ $t('webLayout.dutyLogbook') }}</el-menu-item>
            </el-submenu>
            <el-submenu index="DocumentManagement">
              <template slot="title">
                <i class="el-icon-document-management"></i>
                <span>{{ $t('webLayout.documentManagement') }}</span>
              </template>
              <el-menu-item index="fileupload" @click="jumpView('/fileupload')" v-if="$store.getters.menuFuntionAuth('DOCBANK')">{{ $t('webLayout.documentUpload') }}</el-menu-item>
              <el-menu-item index="fileupload" @click="checkVersion" v-else>{{ $t('webLayout.documentUpload') }}</el-menu-item>

              <el-menu-item index="fileuploadtable" @click="jumpView('/fileuploadtable')" v-if="$store.getters.menuFuntionAuth('DOCBANK')">{{ $t('webLayout.documentAccessRecords') }}</el-menu-item>
              <el-menu-item index="fileuploadtable" @click="checkVersion" v-else>{{ $t('webLayout.documentAccessRecords') }}</el-menu-item>

              <el-menu-item index="myfilehistory" @click="jumpView('/myfilehistory')" v-if="$store.getters.menuFuntionAuth('DOCBANK')">{{ $t('webLayout.myDocuments') }}</el-menu-item>
              <el-menu-item index="myfilehistory" @click="checkVersion" v-else>{{ $t('webLayout.myDocuments') }}</el-menu-item>
            </el-submenu>

            <el-submenu index="StatisticsAnalysis">
              <template slot="title">
                <i class="el-icon-statistics-analysis"></i>
                <span>{{ $t('webLayout.statisticsAnalysis') }}</span>
              </template>
              <el-menu-item index="rptcenter" @click="jumpView('/rptcenter')">{{ $t('webLayout.reportCenter') }}</el-menu-item>

              <el-menu-item index="rcwosum" @click="jumpView('/rcwosum')" v-if="$store.getters.menuFuntionAuth('ANALYSIS')">{{ $t('webLayout.inspectionEfficiencyDashboard') }}</el-menu-item>
              <el-menu-item index="rcwosum" @click="checkVersion" v-else>{{ $t('webLayout.inspectionEfficiencyDashboard') }}</el-menu-item>

              <el-menu-item index="eventsum" @click="jumpView('/eventsum')" v-if="$store.getters.menuFuntionAuth('ANALYSIS')">{{ $t('webLayout.incidentReportDashboard') }}</el-menu-item>
              <el-menu-item index="eventsum" @click="checkVersion" v-else>{{ $t('webLayout.incidentReportDashboard') }}</el-menu-item>

              <el-menu-item index="response_time_dashboard" @click="jumpView('/response_time_dashboard')" v-if="$store.getters.menuFuntionAuth('ANALYSIS')">{{ $t('webLayout.workOrderResponseTimeDashboard') }}</el-menu-item>
              <el-menu-item index="response_time_dashboard" @click="checkVersion" v-else>{{ $t('webLayout.workOrderResponseTimeDashboard') }}</el-menu-item>

              <el-menu-item index="locsum" @click="jumpView('/locsum')" v-if="$store.getters.menuFuntionAuth('ANALYSIS')">{{ $t('webLayout.abnormalityDashboard') }}</el-menu-item>
              <el-menu-item index="locsum" @click="checkVersion" v-else>{{ $t('webLayout.abnormalityDashboard') }}</el-menu-item>

              <el-menu-item index="hrsum" @click="jumpView('/hrsum')" v-if="$store.getters.menuFuntionAuth('ANALYSIS')">{{ $t('webLayout.humanEfficiencyDashboard') }}</el-menu-item>
              <el-menu-item index="hrsum" @click="checkVersion" v-else>{{ $t('webLayout.humanEfficiencyDashboard') }}</el-menu-item>
            </el-submenu>

            <el-submenu index="InventoryMaterial">
              <template slot="title">
                <i class="el-icon-inventory-material"></i>
                <span>{{ $t('webLayout.inventoryMaterial') }}</span>
              </template>
              <el-menu-item index="inventory_management" v-if="$store.getters.menuFuntionAuth('ITEM')" @click="jumpView('/inventory_management')">{{ $t('webLayout.inventoryManagement') }}</el-menu-item>
              <el-menu-item index="inventory_management" v-else @click="checkVersion">{{ $t('webLayout.inventoryManagement') }}</el-menu-item>

              <el-menu-item index="material_usage_record" v-if="$store.getters.menuFuntionAuth('ITEM')" @click="jumpView('/material_usage_record')">{{ $t('webLayout.materialUsageRecord') }}</el-menu-item>
              <el-menu-item index="material_usage_record" v-else @click="checkVersion">{{ $t('webLayout.materialUsageRecord') }}</el-menu-item>
            </el-submenu>
            <!-- 借用管理 -->
            <el-submenu index="rentalManagement">
              <template slot="title">
                <i class="el-icon-rental"></i>
                <span>{{ $t('webLayout.rentalManagement') }}</span>
              </template>
              <el-menu-item index="rent_property_list" @click="jumpView('/rent_property_list')">{{ $t('webLayout.rentPropertyList') }}</el-menu-item>
              <el-menu-item index="lend_return" @click="jumpView('/lend_return')">{{ $t('webLayout.lendReturn') }}</el-menu-item>
              <el-menu-item index="disposal_order" @click="jumpView('/disposal_order')">{{ $t('webLayout.disposalOrder') }}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-main class="content-wrapper">
        <el-scrollbar v-if="!disabledScroll" style="height: calc(100vh - 60px)">
          <div class="content-header">
            <div class="title">
              <template v-if="title">
                {{ title }}

                <el-tooltip v-if="titleTips" :content="titleTips" placement="bottom" effect="light">
                  <i class="el-icon-question el-icon"></i>
                </el-tooltip>
              </template>
              <template v-else>
                <slot name="title"></slot>
              </template>
            </div>
            <div class="extend-content">
              <slot name="extend"></slot>
            </div>
          </div>
          <div class="content-main">
            <slot></slot>
          </div>
        </el-scrollbar>
        <template v-else>
          <div class="content-header">
            <div class="title">
              <template v-if="title">
                {{ title }}

                <el-tooltip v-if="titleTips" :content="titleTips" placement="bottom" effect="light">
                  <i class="el-icon-question el-icon"></i>
                </el-tooltip>
              </template>
              <template v-else>
                <slot name="title"></slot>
              </template>
            </div>
            <div class="extend-content">
              <slot name="extend"></slot>
            </div>
          </div>
          <div class="content-main" style="height: calc(100vh - 60px - 56px)">
            <slot></slot>
          </div>
        </template>
      </el-main>
    </el-container>

    <!-- 版本更新内容弹窗 -->
    <el-dialog custom-class="release-dialog" :visible.sync="releaseLogVisible" :title="$t('common.updateInstruction')">
      <div class="release-content-wrapper">
        <p v-for="(item, index) in releaseLogContent" :key="index">
          {{ item }}
        </p>
      </div>
      <div class="button-wrapper">
        <el-button type="primary" @click="releaseLogVisible = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <new-guide-component v-if="isGuide"></new-guide-component>
  </el-container>
</template>

<script>

import NewGuideComponent from '@/components/NewGuide'
export default {
  name: 'WebLayout',
  props: {
    title: String,
    titleTips: String,
    disabledScroll: Boolean,
  },
  components: {
    NewGuideComponent,
    // GuideBoxComponent,
  },
  data() {
    return {
      dark: false, // 黑色主题
      // 语言选项
      languageItemArr: [
        {
          label: 'English',
          value: 'en',
        },
        {
          label: '简体中文',
          value: 'zh',
        },
        {
          label: '繁體中文(港澳)',
          value: 'zht',
        },
        {
          label: '繁體中文(台灣)',
          value: 'tw',
        },
      ],
      // 菜单是否折叠
      isMenuCollapse: false,
      // 头部菜单高亮
      navActiveKey: 'home',
      // 默认头部logo
      headerDefaultLogo: require('@/assets/03.png'),
      // 站点logo
      headuserlogo: '',
      // 用户名称
      headerDisplayname: '',
      // 侧边菜单高亮
      menuActiveKey: '',
      // 订阅列表
      subscribeList: [],
      // 安全事件闪烁
      safetyIncidentFlashing: false,
      isHistoryStatus: true,
      headerVersionname: '',
      accountViewSetting: {
        caneditdisplaypedemo: false,
        isdisplaybottom: false,
        isdisplaycontact: false,
        isdisplaypedemo: false,
      },
      headversion: '',
      menuOpeneds: [],
      //? 向导组件----------
      guideVisible: false,
      guideSteps: [], // 向导配置
      guideStep: 0, // 当前向导步骤
      // sso配置弹窗
      dialogSsoConfigVisible: false,
      isHideTopMenuLabel: false, // 是否隐藏头部label
      releaseLogContent: '', // 更新内容
      releaseLogVisible: false, // 更新内容弹窗显示
      userinfoPopperVisible: false, // 账号信息弹框
      oldSteps: [], // 之前的向导步骤
      helpDocUrl: '', // 帮助文档链接
      wsSocket: null, // ws连接
    }
  },
  created() {
    this.getTheme()
    // if (this.title) {
    //   document.title = this.title
    // }
    this.isMenuCollapse = this.$store.getters.isMenuCollapse
    this.safetyIncidentFlashing = this.$store.getters.isSafetyIncidentFlashing
    // 设置订阅列表
    this.subscribeList = [
      // 监听用户名变化
      this.$pubsub.subscribe('displaynameChangeEvent', (message, data) => {
        console.log('Received data:', data)
        this.headerDisplayname = data
      }),
      this.$pubsub.subscribe('menuOpenedChangeEvent', () => {
        this.menuOpeneds.push('InspectionPlan')
      }),
      // 监听安全事件
      this.$pubsub.subscribe('safetyIncidentEvent', (message, data) => {
        this.safetyIncidentFlashing = data
        this.$store.commit('SET_SAFETY_INCIDENT_FLASHING', data)
        if (!data) {
          this.$alarm.stop()
        }
      }),
      // 监听打开菜单事件
      this.$pubsub.subscribe('openMenuEvent', (msg, data) => {
        if (data) {
          if (this.menuOpeneds.indexOf(data) > -1) return
          this.menuOpeneds.push(data)
        }
      }),
      // 监听打开向导所在位置
      this.$pubsub.subscribe('showGuidePositionEvent', (msg, data) => {
        document.body.classList.add('show-userinfo')
        window.guideDriver.destroy()
        window.guideDriver.setSteps([
          {
            element: '#newbie_guide',
            popover: {
              showButtons: ['next'],
              nextBtnText: this.$t('guide.ok'),
              popoverClass: 'no-close',
              progressText: '',
              description: data.home.guide_here,
              side: 'left',
              align: 'center',
              onNextClick: () => {
                window.guideDriver.destroy()
                this.userinfoPopperVisible = false
                document.body.classList.remove('show-userinfo')
              },
            },
          },
        ])
        window.guideDriver.highlight({ element: '.user-info-box' })
        setTimeout(() => {
          this.userinfoPopperVisible = true
          this.$nextTick(() => {
            setTimeout(() => {
              window.guideDriver.drive()
            }, 350)
          })
        }, 500)
      }),
    ]

    const pathArr = this.$route.path.split('/')

    if (pathArr.length > 1) {
      const topMenuKeys = ['home', 'property_list', 'notice', 'common_reason_list', 'inspection_monitoring', 'safety_incident', 'sso', 'sla', 'visitor_question_tmpl_management', 'class_management']
      const menuKeyMapping = {
        rcwodetail: 'rcwo',
        wodetail: 'wolist',
        taskdetail: 'task',
        updateTask: 'task',
        wotemplatedetail: 'wotemplatelist',
        wotemplatecreate: 'wotemplatelist',
        updateWOtemplate: 'wotemplatelist',
        woCreate: 'wotemplatelist',
        woEdit: 'wotemplatelist',
        signintemplatecreate: 'signintemplatelist',
        signintemplateupdate: 'signintemplatelist',
        monitorcreate: 'monitorlist',
        updatemonitor: 'monitorlist',
        inventory_material: 'inventory_management',
        meterreadingdetail: 'meterreadinglist',
      }
      if (topMenuKeys.indexOf(pathArr[1]) > -1) {
        // 点击顶部导航菜单，默认高亮左边菜单第一个
        this.navActiveKey = pathArr[1]
        this.menuActiveKey = ''
      } else {
        this.navActiveKey = ''
        this.menuActiveKey = menuKeyMapping[pathArr[1]] || pathArr[1]
      }
    }

    if (this.$store.getters.loginid) {
      //* 更新用户数据(用户信息接口)
      this.updateUserInfo(() => {
        // if (this.$store.getters.headerPedetail == null && this.$store.getters.penum != null && this.$store.getters.penum != "") {
        //* 大概是没有使用中的物业时调用获取物业API
        this.headerGetPeDetail()
        // }
        this.setLogo()

        if (pathArr[1] !== 'create') {
          this.runGuide()
        }
      })

      this.getHistoryStatus()
    }
    // console.log(this.$route.path.sp)
  },
  mounted() {
    this.createWsConnection()
    this.getReleaselog()
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
    if (sessionStorage.getItem('expire')) {
      let expireMsg = sessionStorage.getItem('expire')
      this.$alert(expireMsg, this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
        customClass: 'expire-tips-box',
        callback: () => {
          sessionStorage.removeItem('expire')
        },
      })
    }
    setTimeout(() => {
      this.scrollToCurrentMenu()
    }, 500)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
    this.subscribeList.forEach((s) => {
      this.$pubsub.unsubscribe(s)
    })
  },
  computed: {
    // 是否显示向导
    isGuide() {
      return this.$store.getters.selfregister || this.$store.getters.isOperate || this.$store.getters.ispemgr
    },
    // 默认用户logo
    defaultUserLogo() {
      return this.dark ? require('@/assets/logo_light.png') : require('@/assets/logow.png')
    },
    // 显示版本名称
    showVersionName() {
      // experience: 体验卡 | basis: 绿卡 | enterprise: 银卡 | vip: 金卡
      const levels = [
        { exp: /experience/i, value: this.$t('webLayout.vipExperience') },
        { exp: /basis/i, value: this.$t('webLayout.vipGreen') },
        { exp: /enterprise/i, value: this.$t('webLayout.vipSilver') },
        { exp: /vip/i, value: this.$t('webLayout.vipGold') },
      ]
      if (this.headversion) {
        let matchItem = levels.find((item) => item.exp.test(this.headversion))
        return matchItem ? matchItem.value : ''
      }
      return ''
    },
    showLanguage() {
      const currentLanguage = this.languageItemArr.find((item) => item.value === this.$store.getters.lang)
      return currentLanguage.label
    },
    // EXPERIENCE 体验卡
    // BASIS 绿卡
    // ENTERPRISE 银卡
    // VIP 金卡
    cardColor() {
      const levels = [
        { exp: /experience/i, value: 'is_darkblue' },
        { exp: /basis/i, value: 'is_green' },
        { exp: /enterprise/i, value: 'is_silvery' },
        { exp: /vip/i, value: 'is_gold' },
      ]
      if (this.headversion) {
        let matchItem = levels.find((item) => item.exp.test(this.headversion))
        return matchItem ? matchItem.value : ''
      }
      return ''
    },
    // 安全事件类型
    incidentTypeList() {
      return [
        {
          label: this.$t('inspMn.SAFETY_INCIDENT_TYPE_ALL'),
          value: '',
        },
        {
          label: this.$t('inspMn.SAFETY_INCIDENT_TYPE_LONGNOMOVE'),
          value: 'LONGNOMOVE',
        },
      ]
    },
  },
  methods: {
    // 请求通知权限
    requestNotificationPermission(message) {
      if (typeof Notification === 'undefined') {
        this.$alert(this.$t('safetyIncident.notSupportNotifyTips'), this.$t('common.alertPrompt'), {
          type: 'error',
          center: true,
        })
        return
      }

      if (Notification.permission === 'granted') {
        this.notificationPermission = true
        message && this.showNotification(message)
      } else {
        const request = Notification.requestPermission()
        if (request && request.then) {
          request.then((permission) => {
            this.notificationPermission = permission === 'granted'
            if (this.notificationPermission) {
              message && this.showNotification(message)
            } else {
              this.$alert(this.$t('safetyIncident.deniedSupportNotifyTips'), this.$t('common.alertPrompt'), {
                type: 'error',
                center: true,
              })
            }
          })
        }
      }
    },

    // 发送通知
    showNotification(message) {
      const options = {
        body: message,
        renotify: true,
        tag: 'safety_incident',
      }

      this.notification = new Notification(this.$t('inspMn.SAFETY_INCIDENT_TITLE'), options)

      // 处理点击事件
      this.notification.onclick = () => {
        window.focus()
        this.notification.close()
      }
    },
    // 创建连接获取数据
    createWsConnection() {
      this.$ws().then((ws) => {
        console.log('createWsConnection--', ws)
        if (!ws) return
        this.wsSocket = ws
        ws.subscribe('all', (data) => {
          console.log('data-all', data)
          let { content, monitorType } = data
          if (content && monitorType) {
            // 安全事件
            if (monitorType === 'SAFETY') {
              // 传播安全事件数据
              this.$pubsub.publish('safetyIncidentDataEvent', content)
              // 提取没有标记位已知晓的数据
              let newsList = content.list.filter((item) => !item.dismiss)
              console.log('newsList', newsList)
              if (newsList.length > 0) {
                this.$pubsub.publish('safetyIncidentEvent', true)

                // 设置循环模式 - 当有未处理的警报时持续播放
                this.$alarm.setLoop(true)
                this.$alarm.play()
                // 将账号名称拼接起来
                let hrNames = [...new Set(newsList.map((item) => item.eventHrName))].join(' , ')
                newsList.forEach((item) => {
                  this.requestNotificationPermission(hrNames + ' ' + this.getIncidentTypeLabel(item.type))
                })
              } else {
                // 关闭闪烁，停止警报
                this.$pubsub.publish('safetyIncidentEvent', false)
                this.$alarm.stop() // 会自动停止循环并重置状态
              }
            }
          }
        })
      })
    },
    // 获取类型的描述
    getIncidentTypeLabel(value) {
      let matchItem = this.incidentTypeList.find((iItem) => iItem.value === value)
      return matchItem ? matchItem.label : ''
    },
    // 滚动到对应的菜单项
    scrollToCurrentMenu() {
      let menuItemActive = document.querySelector('.aside-menu-wrapper .el-scrollbar .el-menu-item.is-active')
      if (menuItemActive) {
        menuItemActive.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    // 获取当前主题
    getTheme() {
      this.dark = this.$store.getters.theme === 'dark'
    },
    // 更换模式
    switchTheme() {
      this.$store.commit('SET_THEME', this.dark ? 'dark' : 'light')
      this.setLogo()
    },
    // 获取最新版本发布日志
    getReleaselog() {
      if (this.$route.path !== '/home') return
      // 缓存1小时，请求节流
      const releaselogtimer = localStorage.getItem('313_releaselogtimer')
      if (releaselogtimer) {
        const releaselogTimestamp = Number(releaselogtimer),
          currentTimestamp = new Date().getTime()
        if (releaselogTimestamp > currentTimestamp) return
      }
      this.$http
        .post('/common/releaselog', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
        })
        .then((result) => {
          if (result.data.success) {
            localStorage.setItem('313_releaselogtimer', new Date().getTime() + 3600000)
            if (result.data.vernum) {
              this.releaseLogContent = result.data.releaselog
              if (Array.isArray(this.releaseLogContent) && this.releaseLogContent.length) {
                this.releaseLogVisible = true
              }
            }
          } else {
            this.$alert(result.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true,
            })
          }
        })
        .catch(() => {})
    },
    setLogo() {
      if (null != sessionStorage.getItem('userlogo') && '' != sessionStorage.getItem('userlogo')) {
        this.headuserlogo = sessionStorage.getItem('userlogo')
      } else {
        this.headuserlogo = this.defaultUserLogo
      }
    },
    // 监听屏幕尺寸变化
    checkScreenSize() {
      // 先不收起，为了获取长度
      this.isHideTopMenuLabel = false
      this.$nextTick(() => {
        let headerMainBox = document.querySelector('.header-main-box')
        let menu = headerMainBox.querySelector('.el-menu')
        let personalInfo = headerMainBox.querySelector('.personal-info-wrapper')
        // 20 (间距，可不设置)
        this.isHideTopMenuLabel = menu.scrollWidth + personalInfo.scrollWidth + 20 > headerMainBox.clientWidth
      })
    },
    // 收缩方法
    shrinkEvent() {
      this.isMenuCollapse = !this.isMenuCollapse
      this.$store.commit('SET_MENU_COLLAPSE', this.isMenuCollapse)
    },
    // 获取用户信息
    updateUserInfo(callback) {
      this.$http
        .post(
          '/common/userinfo',
          {
            hrid: this.$store.getters.loginid,
          },
          {
            showLoading: false,
          }
        )
        .then((res) => {
          if (res.data.success) {
            const content = res.data.content
            // 有安全卫士功能和安全卫士有数量且大于0
            // if (content.funcList.SAFETY && content.funcLimit.SAFETY_HR_LIMIT) {
            // if (content.funcList.SAFETY) {
            //   this.createWsConnection()
            // }
            // 设置时差
            sessionStorage.setItem('timeoffset', content.timeoffset)
            this.loclimit = content.loclimit || 0
            this.enddate = content.enddate ? this.$utils.format(new Date(content.enddate), 'Y-m-d') : ''
            this.helpDocUrl = content.helpdocurl
            // console.log("content");
            // console.log(content);
            this.$store.commit('SET_LOC_LIMIT', this.loclimit)
            // 短信提醒开关
            this.$store.commit('SET_SWITCH', content.switch || false)
            // 短信提醒剩余数量
            this.$store.commit('SET_SMSRESIDUE', content.smsresidue || 0)
            // 语音提醒剩余数量
            this.$store.commit('SET_VOICERESIDUE', content.voiceresidue || 0)

            this.$store.commit('SET_SELFREGISTER', content.selfregister)
            this.$store.commit('SET_ISPEMGR', content.ispemgr)
            this.$store.commit('SET_ISOPERATE', content.isdataadmin)

            // sessionStorage.setItem("selfregister", (content.selfregister ? "Y" : "N"));
            // sessionStorage.setItem("ispemgr", (content.ispemgr ? "true" : "false"));
            // sessionStorage.setItem("isOperate", (content.isdataadmin ? "true" : "false"));
            // // sessionStorage.setItem("language", (content.language));

            // 切换语言
            this.$store.commit('SET_LANG', content.language)
            this.$i18n.locale = this.$store.getters.lang

            // sessionStorage.setItem("suffix", (content.suffix));
            // 物业实体简称后缀
            this.$store.commit('SET_SUFFIX', content.suffix)

            this.isSelfRegister = content.selfregister
            // 头部的用户名
            this.headerDisplayname = content.displayname
            this.$store.commit('SET_DISPLAYNAME', content.displayname)
            // 头部用户的权限卡
            // this.headerVersionname = content.version;
            this.accountViewSetting = {
              caneditdisplaypedemo: content.caneditdisplaypedemo,
              isdisplaybottom: content.isdisplaybottom,
              isdisplaycontact: content.isdisplaycontact,
              isdisplaypedemo: content.isdisplaypedemo,
            }
            this.checkScreenSize()
            //console.log('bottom:'+ this.accountViewSetting.isdisplaybottom);
            sessionStorage.setItem('caneditdisplaypedemo', content.caneditdisplaypedemo ? 'true' : 'false')
            sessionStorage.setItem('isdisplaybottom', content.isdisplaybottom ? 'true' : 'false')
            sessionStorage.setItem('isdisplaycontact', content.isdisplaycontact ? 'true' : 'false')
            sessionStorage.setItem('isdisplaypedemo', content.isdisplaypedemo ? 'true' : 'false')
            sessionStorage.setItem('userlogo', content.weblogourl)

            this.$store.commit('SET_FUNC_LIMIT', content.funcLimit)
            this.$store.commit('SET_MENU_FUNTIONAUTH', content.funcList)
            this.$store.commit('SET_MAPTYPE', content.maptype)
            // this.$store.commit('SET_MAPTYPE', 'GOOGLE')

            // sessionStorage.setItem("alert", funclist.ALERT ? '1' : '0');
            // sessionStorage.setItem("alert_loc", funclist.ALERT_LOC ? '1' : '0');
            // sessionStorage.setItem("analysis", funclist.ANALYSIS ? '1' : '0');
            // sessionStorage.setItem("diy_logo", funclist.DIY_LOGO ? '1' : '0');
            // sessionStorage.setItem("map_loc", funclist.MAP_LOC ? '1' : '0');
            // sessionStorage.setItem("rc_notice", funclist.RC_NOTICE ? '1' : '0');
            // sessionStorage.setItem("rc_report", funclist.RC_REPORT ? '1' : '0');
            // sessionStorage.setItem("rc_task", funclist.RC_TASK ? '1' : '0');
            // sessionStorage.setItem("rc_temp", funclist.RC_TEMP ? '1' : '0');
            // sessionStorage.setItem("up_pic", funclist.UP_PIC ? '1' : '0');
            // sessionStorage.setItem("rc_scanagain", funclist.RC_SCANAGAIN ? '1' : '0');
            // sessionStorage.setItem("rc_nocancel", funclist.RC_NOCANCEL ? '1' : '0');

            // sessionStorage.setItem("up_video", funclist.UP_VIDEO ? '1' : '0');
            // sessionStorage.setItem("page_setting", funclist.PAGE_SETTING ? '1' : '0');
            // sessionStorage.setItem("wo_version", funclist.WO ? '1' : '0');
            // sessionStorage.setItem("visitor_version", funclist.VISITOR ? '1' : '0');
            // sessionStorage.setItem("wotemp_version", funclist.WO_TEMP ? '1' : '0');
            // sessionStorage.setItem("loc_import", funclist.LOC_IMPORT ? '1' : '0'); // 巡检点导入权限
            // sessionStorage.setItem("holiday_import", funclist.HOLIDAY_IMPORT ? '1' : '0'); // 假期导入权限
            // sessionStorage.setItem("pause_we", funclist.PAUSE_WE ? '1' : '0'); // 应用巡检计划开启条件
            // sessionStorage.setItem("continuercwo", funclist.CONTINUERCWO ? '1' : '0'); // 自动生成下一张巡检单？
            // sessionStorage.setItem("rpt_send_setting", funclist.RPT_SEND_SETTING ? '1' : '0') // 报表中心邮件自动发送
            // sessionStorage.setItem("sms_setting", funclist.SMS_SETTING ? '1' : '0') // 巡检计划/工单 完成提醒
            // sessionStorage.setItem("tasktemplate_version", funclist.PUNCHIN ? '1' : '0') // 范本任务
            // sessionStorage.setItem("doc_version", funclist.DOCBANK ? '1' : '0') // 文档库
            // sessionStorage.setItem("cm_version", funclist.CM ? '1' : '0') // 监控模板
            // sessionStorage.setItem("item_version", funclist.ITEM ? '1' : '0') // 库存物料
            // sessionStorage.setItem("auth_group_version", funclist.AUTH_GROUP ? '1' : '0') // 用户权限组

            // sessionStorage.setItem("up_piccount", content.funcLimit.UP_LIMIT);
            this.$store.commit('SET_UP_LIMIT', content.funcLimit.UP_LIMIT)
            // sessionStorage.setItem("version", content.accountVersion);
            // sessionStorage.setItem("maptype", (content.maptype));
            // sessionStorage.setItem("user_areacode", (content.areacode));
            // sessionStorage.setItem("joblog", funclist.JOB_LOG ? '1' : '0');

            this.headversion = content.accountVersion

            // this.alerttemp = sessionStorage.getItem('alert');
            // this.navanalysis = sessionStorage.getItem('analysis');
            // this.navwo = sessionStorage.getItem('wo_version');
            // this.navwotemp = sessionStorage.getItem('wotemp_version');
            // // this.joblog = funclist.JOB_LOG ? '1' : '0';
            // this.visitorVersion = sessionStorage.getItem('visitor_version');
            // // console.log(this.navanalysis);
            // this.tasktemplatetemp = sessionStorage.getItem('tasktemplate_version');
            // this.doctemp = sessionStorage.getItem('doc_version');
            // this.cmtemp = sessionStorage.getItem('cm_version');
            // this.itemVersion = sessionStorage.getItem('item_version');

            // this.authGroupVersion = sessionStorage.getItem('auth_group_version');
            //? 更新权限项
            this.$store.commit('SET_AUTH_GROUP_FUNCLIST', content.authGroupFuncList)
            // this.authGroupFuncList = content.authGroupFuncList
            // //? 删除先前保存的权限项
            // sessionStorage.removeItem('authgroup_visitor');

            // console.log('------ content.authGroupFuncList', content.authGroupFuncList)
            // //? 重新缓存权限组权限
            // content.authGroupFuncList.forEach(item => {
            //   sessionStorage.setItem(`authgroup_${item.toLowerCase()}`, true);
            // })
            this.oauth2_login = !content.funcList.OAUTH2_LOGIN
            // this.contact();
            if (callback) {
              callback()
            }
            this.$pubsub.publish('userinfoCompleted')
          } else {
            // showDialog(this.pageText.PROMPT, res.data.message, "error", "", [this.pageText.OK]);
            this.$alert(res.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true,
            })
          }
        })
    },
    // 获取物业
    headerGetPeDetail(jumpurl) {
      this.$http
        .post(
          '/home/changepe',
          {
            hrid: this.$store.getters.loginid,
            penum: this.$store.getters.penum,
          },
          {
            showLoading: false,
          }
        )
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('SET_HEADER_PEDETAIL', {
              description: response.data.content.description,
              pelogo: response.data.content.pelogo || this.headerDefaultLogo,
            })
            if (jumpurl != '' && jumpurl != null && jumpurl != undefined) {
              location.href = jumpurl
            }
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true,
            })
            // showDialog(this.pageText.PROMPT, response.data.message, "error", "", [this.pageText.OK]);
          }
        })
    },
    // 切换物业
    headerChangePeDetail(penum, jumpurl) {
      this.$http
        .post('/home/changepe', {
          hrid: this.$store.getters.loginid,
          penum: penum,
        })
        .then((response) => {
          if (response.data.success) {
            if (response.data.targethrid) {
              this.$store.commit('SET_LOGINID', response.data.targethrid)
              // sessionStorage.setItem("loginid", response.data.targethrid);
              // this.hrid = response.data.targethrid
            }

            this.$store.commit('SET_HEADER_PEDETAIL', {
              description: response.data.content.description,
              pelogo: response.data.content.pelogo || this.headerDefaultLogo,
            })

            if (response.data.targetpenum) {
              this.$store.commit('SET_PENUM', response.data.targetpenum)
              // sessionStorage.setItem("penum", response.data.targetpenum);
            } else {
              this.$store.commit('SET_PENUM', penum)
              // sessionStorage.setItem("penum", (penum));
            }
            // 清除当前ws，然后重新创建新连接
            this.wsSocket && this.wsSocket.destroy()
            this.createWsConnection()

            this.updateUserInfo(() => {
              this.$message({
                message: this.$t('webLayout.switchPropertySuccess'),
                type: 'success',
              })
            }) // 刷新用户权限

            if (jumpurl != '' && jumpurl != null && jumpurl != undefined) {
              location.href = jumpurl
            }
          } else {
            // showDialog(this.pageText.PROMPT, response.data.message, "error", "", [this.pageText.OK]);
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true,
            })
          }
        })
    },
    // 切换语言
    switchLanguage(lang) {
      this.$http.post('/home/changelang', { hrid: this.$store.getters.loginid, language: lang }).then((response) => {
        if (response.data.success) {
          this.$store.commit('SET_LANG', lang)
          this.$i18n.locale = this.$store.getters.lang
          this.$pubsub.publish('langSwitchEvent')
          this.$pubsub.publish('resetStepsEvent')
          this.checkScreenSize()
          this.$message({
            message: this.$t('webLayout.switchLangSuccess'),
            type: 'success',
          })
        }
      })
    },
    // 跳转页面
    jumpView(url) {
      // 如果处于向导状态，已在当前页就下一步，否则关闭(不然会弹出两个)
      if (this.$store.getters.isShowGuide) {
        if (this.$route.path === url) {
          if (url === '/create') {
            this.$pubsub.publish('setCreateTaskTabsEvent')
          }
          window.guideDriver.moveNext()
        } else {
          window.guideDriver.destroy()
        }
      }
      // 同一个页面就不跳转了，以免报错
      if (this.$route.path === url) return
      this.$store.commit('REMOVE_FILTER_RECORDS_PAGE')
      this.$router.push(url)
    },
    // 显示版本信息
    checkVersion() {
      this.$alert(this.$t('common.noSupportTips'), this.$t('common.alertPrompt'), {
        type: 'error',
        center: true,
      })
    },
    goUserinfo() {
      this.$router.push('/userinfo')
    },
    getOfficialWebsite() {
      if (this.$store.getters.lang == 'zht') {
        window.open('https://www.313fm.com/tc/')
      } else if (this.$store.getters.lang == 'en') {
        window.open('https://www.313fm.com/en/')
      } else {
        window.open('https://www.313fm.com')
      }
    },
    // getCustomerServiceUrl() {
    //   if (this.$store.getters.lang == 'zht' || this.$store.getters.lang == 'en') {
    //     window.open('http://cn.313fm.com/support/dialog_2.htm')
    //   } else {
    //     window.open('http://cn.313fm.com/support/dialog_1.htm')
    //   }
    // },
    // 历史记录
    goToHistoryPage() {
      this.$http.post(
        '/common/releaseloglist',
        {
          type: 'WEB',
          hrid: this.$store.getters.loginid,
          version: true,
        },
        {
          showLoading: false,
        }
      )
      this.$router.push('/release_history')
    },
    getHistoryStatus() {
      this.$http
        .post(
          '/common/releaseloglist',
          {
            type: 'WEB',
            hrid: this.$store.getters.loginid,
          },
          {
            showLoading: false,
          }
        )
        .then((res) => {
          if (res && res.success) {
            this.isHistoryStatus = res.data.content.status
          }
        })
    },
    // 手动开启向导，弹出步骤概览
    openNewbieGuide() {
      this.$emit('openGuideCallback')
      document.body.classList.remove('show-userinfo')
      this.userinfoPopperVisible = false
      window.guideDriver && window.guideDriver.destroy()
      // 判断是否有场景，没有就显示选择场景的，有就显示步骤总览
      if (!this.$store.getters.guideScene) {
        this.$pubsub.publish('showSceneDialogEvent')
      } else {
        let path = this.$route.path.split('/')[1]

        let registerOrAdmin = this.$store.getters.selfregister || this.$store.getters.isOperate
        // 对应步骤的权限
        let permission = {
          create_property: this.$store.getters.selfregister,
          create_account: registerOrAdmin && !this.$store.getters.isDemo,
          create_location: registerOrAdmin && !this.$store.getters.isDemo,
          create_plan: (registerOrAdmin || this.$store.getters.ispemgr) && !this.$store.getters.isDemo,
          download_app: registerOrAdmin || this.$store.getters.ispemgr,
        }
        // 页面对应的步骤总览步骤
        let pageOverviewStep = {
          create_property: /^(property_list|basicinfo)$/,
          create_account: /^account$/,
          create_location: /^location$/,
          create_plan: /^create$/,
          download_app: /.*/, // 兜底，其他页面
        }
        // 先判断是否有权限，有权限再匹配当前页面向导
        for (const key of Object.keys(permission)) {
          if (permission[key] && pageOverviewStep[key].test(path)) {
            if (key === 'download_app') {
              // 提取第一个符合条件的权限键
              let permissionKey = Object.keys(permission).find((key) => permission[key])
              this.$pubsub.publish('overviewStepEvent', { key: permissionKey, show: true })
            } else {
              this.$pubsub.publish('overviewStepEvent', { key, show: true })
            }
            return
          }
        }
      }
    },
    // // 手动开启向导，弹出步骤概览
    // openNewbieGuide() {
    //   this.userinfoPopperVisible = false
    //   this.$store.commit('SET_ISSHOWGUIDE', true)
    //   let path = this.$route.path.split('/')[1]
    //   if (path === 'home') {
    //     window.guideDriver.destroy()
    //     this.$store.commit('SET_SHOW_GUIDE_POSITION', false)
    //     let permission = {
    //       create_property: this.$store.getters.selfregister,
    //       create_account: (this.$store.getters.selfregister || this.$store.getters.isOperate) && !this.$store.getters.isDemo,
    //       create_location: (this.$store.getters.selfregister || this.$store.getters.isOperate) && !this.$store.getters.isDemo,
    //       create_plan: (this.$store.getters.selfregister || this.$store.getters.isOperate || this.$store.getters.ispemgr) && !this.$store.getters.isDemo,
    //       download_app: this.$store.getters.selfregister || this.$store.getters.isOperate || this.$store.getters.ispemgr
    //     }
    //     // 提取第一个符合条件的权限键
    //     let permissionKey = Object.keys(permission).find(key => permission[key]);
    //     if (permissionKey) {
    //       this.$pubsub.publish('overviewStepEvent', {key: permissionKey, show: true})
    //     }
    //     return
    //   }
    //   if (path === 'basicinfo') {
    //     window.guideDriver.destroy()
    //     this.$store.commit('SET_SHOW_GUIDE_POSITION', false)
    //     this.$pubsub.publish('overviewStepEvent', {key: 'create_property', show: true})
    //     return
    //   }
    //
    //   // 没有选中场景逻辑，就直接显示阶段弹窗
    //   if (!this.$store.getters.guideScene) {
    //     window.guideDriver.destroy()
    //     this.$store.commit('SET_SHOW_GUIDE_POSITION', false)
    //     let pageObj = {
    //       property_list: 'create_property',
    //       account: 'create_account',
    //       location: 'create_location',
    //       create: 'create_plan',
    //     }
    //     this.$pubsub.publish('overviewStepEvent', { key: pageObj[path], show: true })
    //   } else {
    //     window.guideDriver && window.guideDriver.destroy()
    //     // 如果当前时处于显示向导所在位置时需要关闭，重新设置向导步骤
    //     if (this.$store.getters.showGuidePosition) {
    //       this.$store.commit('SET_SHOW_GUIDE_POSITION', false)
    //       window.guideDriver.setSteps(this.oldSteps)
    //     }
    //     window.guideDriver && window.guideDriver.drive()
    //   }
    // },
    // 登出
    loginOut() {
      this.$http
        .post('/common/logout', {
          hrid: this.$store.getters.loginid,
        })
        .then((response) => {
          sessionStorage.clear()
          localStorage.removeItem('313_releaselogtimer')
          this.$store.commit('RESET_GUIDE_SCENE')
          this.$store.commit('SET_SAFETY_INCIDENT_FLASHING', false)
          this.$alarm.stop()
          this.wsSocket && this.wsSocket.destroy()
          if (response.data.success) {
            if (response.data.oauthlogouturl == null) {
              this.$router.push('/login')
            } else {
              window.location.href = response.data.oauthlogouturl
            }
          } else {
            this.$router.push('/login')
          }
        })
    },
    visitorQuestion() {
      const { href } = this.$router.resolve({
        path: '/showimage',
      })
      window.open(href)
    },
    // 向导功能
    // 启动向导
    runGuide(pagename) {
      let pagenameTemp = this.$route.path.split('/')[1]
      if (pagenameTemp == 'home') {
        pagenameTemp = 'property_list'
      } else if (pagenameTemp == 'property_list') {
        pagenameTemp = 'home'
      }
      const reqData = {
        hrid: this.$store.getters.loginid,
        pagename: pagename ? 'home' : pagenameTemp,
        pagenameStatus: this.$route.path.split('/')[1] == 'property_list' || this.$route.path.split('/')[1] == '',
      }
      const openGuide = sessionStorage.getItem('openGuide')
      // 手动开启向导
      if (openGuide) reqData.force = openGuide
      const guideBrowsedCompletion = sessionStorage.getItem('guideBrowsedCompletion')
      const completionGuide = sessionStorage.getItem('completionGuide')
      if (guideBrowsedCompletion && completionGuide && guideBrowsedCompletion === completionGuide && openGuide != '0') reqData.current = guideBrowsedCompletion

      if (this.$route.path.split('/')[1] == 'account') {
        const accountRepeatGuide = sessionStorage.getItem('accountRepeatGuide')
        if (accountRepeatGuide) {
          reqData.repeat = true
          sessionStorage.removeItem('locationRepeatGuide')
        }
      }

      if (this.$route.path.split('/')[1] == 'location') {
        const locationRepeatGuide = sessionStorage.getItem('locationRepeatGuide')
        if (locationRepeatGuide) {
          reqData.repeat = true
          sessionStorage.removeItem('accountRepeatGuide')
        }
      }
    },
    // 关闭向导
    guideClose() {
      // 关闭手动开启向导
      sessionStorage.removeItem('guideBrowsedCompletion')
      sessionStorage.setItem('openGuide', '0')
    },
    // 浏览完成当前页向导完成最后一步
    guideFinish(step, stepObj) {
      if (stepObj && Object.hasOwnProperty.call(stepObj, 'to')) {
        // console.log('浏览完成当前页向导完成最后一步');
        // 关闭手动开启向导
        // sessionStorage.setItem('openGuide', '0');
        // 向导浏览完毕
        sessionStorage.setItem('guideBrowsedCompletion', stepObj.to)
      }
    },
    /**
     * 向导完成处理函数
     * @param {String} guidename 完成的向导名
     */
    handleGuideFinish(guidename) {
      // sessionStorage.removeItem('repeatGuide');
      // 当前创建巡检计划是向导的最后一项，当完成最后一项向导时不需要进行其他操作
      if (guidename === 'create') {
        sessionStorage.setItem('openGuide', '0')
        this.runGuide()
        return
      }
      const guideBrowsedCompletion = sessionStorage.getItem('guideBrowsedCompletion')
      // 当浏览完毕的向导 与 完成的向导名称相同时，返回首页并切换向导
      if (guideBrowsedCompletion === guidename) {
        sessionStorage.setItem('completionGuide', guidename) // 向导执行完成(数据创建完成)
        if (guidename != 'entity') {
          this.runGuide('home', guidename)
        }
      } else {
        if (guidename != 'entity') {
          this.runGuide()
        }
      }
    },
    handleCloseGuide() {
      // sessionStorage.removeItem('repeatGuide');
      // this.$refs.sGuide.nextStep()
      this.$refs.guideBox.cancelClose()
      this.$refs.guideBox.hideGuideDriver()
    },
    handleNextStep() {
      sessionStorage.setItem('guideBrowsedCompletion', 'entity')
    },
    rebuild(stepsData) {
      sessionStorage.setItem('openGuide', '1')
      if (stepsData.current == 'location') {
        sessionStorage.setItem('accountRepeatGuide', '1')
        sessionStorage.removeItem('locationRepeatGuide')
      } else if (stepsData.current == 'create') {
        sessionStorage.setItem('locationRepeatGuide', '1')
        sessionStorage.removeItem('accountRepeatGuide')
      } else {
        sessionStorage.removeItem('locationRepeatGuide')
        sessionStorage.removeItem('accountRepeatGuide')
      }

      // if(stepsData.current == 'location'){
      // 	sessionStorage.setItem('accountRepeatGuide', '1');
      // }
      this.runGuide()
    },
    // 打开sso配置页面
    openOauthConfig() {
      this.dialogSsoConfigVisible = true
    },
    // 关闭sso页面
    closeOauthConfig() {
      this.dialogSsoConfigVisible = false
    },
  },
}
</script>
<style lang="scss">
.el-menu--horizontal.top-submenu-popper {
  .el-menu {
    @include setBgColor('topMenuBgColor');
    @include setTextColor('topMenuTextColor');
    .el-menu-item {
      @include setTextColor('topMenuTextColor');
      @include setBgColor('topMenuBgColor');
      &:hover {
        @include setTextColor('sideMenuTextActiveColor');
        @include setBgColor('sideMenuItemActiveBgColor');
      }
      &.is-active {
        @include setTextColor('sideMenuTextActiveColor');
        @include setBgColor('sideMenuItemActiveBgColor');
      }
    }
  }
}
.el-menu--vertical {
  .el-menu {
    @include setBgColor('sideMenuBgColor');
    @include setBorderColor('sideMenuBorderColor');
    .el-menu-item {
      @include setTextColor('sideMenuTextColor');
      &:hover,
      &:focus,
      &.is-active {
        @include setTextColor('sideMenuTextActiveColor');
        @include setBgColor('sideMenuItemActiveBgColor');
      }
    }
  }
}
.el-popper.app-popper {
  top: 50px !important;
  @include setBgColor('topMenuBgColor');
  @include setBorderColor('topMenuBgColor');
  .popper__arrow::after {
    @include setBorderColor('topMenuBgColor', 'bottom');
  }
}
.el-dropdown-menu.lang-popper {
  top: 50px !important;
  @extend .app-popper;
  .el-dropdown-menu__item {
    @include setTextColor('topMenuTextColor');
    @include setBgColor('topMenuBgColor');
    &:hover {
      @include setTextColor('sideMenuTextActiveColor');
      @include setBgColor('sideMenuItemActiveBgColor');
    }
    &.is-active {
      @include setTextColor('sideMenuTextActiveColor');
      @include setBgColor('sideMenuItemActiveBgColor');
    }
  }
}
.el-popper.userinfo-popper {
  top: 50px !important;
  @extend .app-popper;
  .userinfo-more-wrapper {
    > div,
    > a {
      text-decoration: none;
      span {
        @include setTextColor('topMenuTextColor');
        i {
          margin-right: 16px;
        }
      }
      &:hover {
        > span {
          @include setTextColor('topMenuTextActiveColor');
        }
      }
    }
    .card_box {
      span {
        color: #fff !important;
      }
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.web-layout-container.el-container {
  min-width: 1200px;
  min-height: 100vh;

  .content-wrapper {
    padding: 0;

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      height: 56px;
      border-bottom: 1px solid $--border-color-content;
      box-sizing: border-box;
      // @include setBgColor('contentTitleBgColor');
      // @include setTextColor('contentTitleTextColor');
      .title {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .content-main {
      padding: 20px;
      box-sizing: border-box;
      height: 100%;

      // min-height: calc(100vh - 60px - 56px);
      // overflow: auto;
    }
  }
}
.header-wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  // border-bottom: 2px solid #F3F3F7;
  padding: 0;
  z-index: 5;
  transition: background 0.3s;
  @include setBgColor('topMenuBgColor');
  .el-container {
    align-items: center;
  }

  .logo-box {
    width: 270px !important;

    img {
      margin-left: 20px;
      height: 34px;
      vertical-align: middle;
    }
  }

  .header-main-box {
    padding: 0;
    display: flex;
    justify-content: space-between;

    .el-menu--horizontal {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      background: transparent;
      border-bottom: 0;
      @mixin menu-icon {
        font-size: 16px;
        @include setTextColor('topMenuTextColor');
      }
      /deep/.el-submenu {
        &.is-active .el-submenu__title {
          @include setTextColor('topMenuTextActiveColor');
          i {
            @include setTextColor('topMenuTextActiveColor');
          }
        }
        .el-submenu__title {
          border-width: 3px;
          font-weight: 600;
          @include setTextColor('topMenuTextColor');
          &:hover {
            background-color: transparent;
          }
          i {
            @include menu-icon;
          }
        }
      }
      > .el-menu-item {
        border-bottom-width: 3px;
        font-weight: 600;
        @include setTextColor('topMenuTextColor');
        &:hover {
          background-color: transparent;
        }
        i {
          @include menu-icon;
        }
        &.is-active {
          @include setTextColor('topMenuTextActiveColor');
          i {
            @include setTextColor('topMenuTextActiveColor');
          }
        }
        &.safety_flashing,
        &.is-active.safety_flashing {
          border-color: transparent;
          color: #de4444;
          animation: safety_flashing ease-in-out 1s infinite;
          i {
            color: inherit;
          }
          @keyframes safety_flashing {
            0% {
              // opacity: 0.1;
            }
            100% {
              // opacity: 1;
              background: linear-gradient(180deg, rgba(222, 68, 68, 0.3) 0%, rgba(35, 35, 60, 0) 30%, rgba(35, 35, 60, 0) 70%, rgba(222, 68, 68, 0.3) 100%);
            }
          }
        }
      }
    }
  }
  /deep/.el-popover__reference-wrapper {
    display: flex;
  }

  .el-menu.el-menu--horizontal > .el-menu-item {
    padding: 0 15px;
    line-height: 58px;
  }

  .personal-info-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-weight: 600;
    @include setTextColor('topMenuTextColor');

    .name {
      max-width: 100px;
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .head-sculpture {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-right: 20px;
      overflow: hidden;
      background: #000;

      img {
        max-width: none;
      }
    }

    .language-box {
      margin-right: 25px;
      color: inherit;
      cursor: pointer;
    }

    .user-info-box {
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      .phone-box {
        display: inline-block;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        margin-right: 10px;
        border-right: 2px solid $--border-color-content;
      }

      .el-icon-arrow-down {
        transition: transform 0.3s;

        &.active {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.aside-menu-wrapper {
  position: relative;
  border-right: 2px solid;
  overflow: visible;
  @include setBorderColor('sideMenuBorderColor');
  @include setBgColor('sideMenuBgColor');
  .shrink-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 18px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    right: -21px;
    border: 2px solid $--border-color-content;
    border-radius: 3px;
    font-size: $--font-size-extra-small;
    box-sizing: border-box;
    @include setBgColor('sideMenuBgColor');
    @include setBorderColor('sideMenuBorderColor');
    // transition: .3s width ease-in-out, .3s padding-left ease-in-out, .3s padding-right ease-in-out;
    cursor: pointer;
    z-index: 2;
    top: 50%;
    margin-top: -20px;

    .icon-box {
      // position: absolute;
      // right: 0;
      // top: 4px;
      // bottom: 0;
      // background: #fff;
      // padding: 0 20px;
      // line-height: 50px;
      overflow: hidden;
      transition: 0.3s transform ease-in-out;
      transform-origin: center;
      transform: rotate(180deg);
      @include setTextColor('sideMenuTextColor');
    }

    &.is-menu-collapse .icon-box {
      transform: rotate(0deg);
    }
  }

  /deep/.el-scrollbar__wrap {
    &::-webkit-scrollbar {
      width: 17px;
    }
  }
  .menu-box {
    border-right: none;
    @include setBgColor('sideMenuBgColor');
    &.el-menu--collapse {
      .is-active {
        i[class^='el-icon-'] {
          @include setTextColor('topMenuTextActiveColor');
        }
      }
      i[class^='el-icon-'] {
        font-size: $--font-size-medium;
      }
    }
    /deep/ .el-submenu__title {
      .lang-en & {
        padding-left: 10px !important;
        .el-submenu__icon-arrow {
          right: 10px;
        }
      }
      font-weight: 600;
      @include setTextColor('sideMenuTextColor');
      i {
        color: inherit;
        font-size: inherit;
      }
      &:hover {
        background: transparent;
        color: $--color-primary;
      }

      .el-icon-arrow-down {
        font-family: 'element-icons' !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &::before {
          content: '\e64b';
        }
      }
    }

    /deep/ .el-submenu {
      // position: relative;
      &::after {
        position: absolute;
        display: block;
        // bottom: 0;
        left: 15px;
        right: 15px;
        border-bottom: 2px dashed transparent;
        // @include setBorderColor('sideMenuBorderColor');
        content: '';
      }

      .el-menu {
        .lang-en & {
          padding-left: 34px;
        }
        padding: 10px 20px 17px 44px;
        box-sizing: border-box;
        @include setBgColor('sideMenuBgColor');
      }
    }

    .el-submenu .el-menu-item {
      white-space: normal;
      line-height: 1.3;
      height: auto;
      padding: 7.5px 5px !important;
      margin-bottom: 4px;
      border-radius: 10px;
      box-sizing: border-box;
      font-weight: 400;
      @include setTextColor('sideMenuTextColor');
      &:hover,
      &:focus,
      &.is-active {
        @include setTextColor('sideMenuTextActiveColor');
        @include setBgColor('sideMenuItemActiveBgColor');
      }
    }
  }

  .menu-box:not(.el-menu--collapse) {
    width: 270px;
  }
}

// .extend-content {
//   /deep/.el-button {
//     @include setBgColor('btnBgColor');
//     @include setTextColor('btnTextColor');
//     @include setBorderColor('btnBorderColor');
//     &.el-button--primary {
//       @include setBgColor('btnPrimaryBgColor');
//       @include setTextColor('btnPrimaryTextColor');
//       @include setBorderColor('btnPrimaryBorderColor');
//     }
//     &:hover {
//       @include setBgColor('btnHoverBgColor');
//       @include setTextColor('btnHoverTextColor');
//       @include setBorderColor('btnHoverBorderColor');
//     }
//     &:disabled:hover {
//       @include setBgColor('btnBgColor');
//       @include setTextColor('btnTextColor');
//       @include setBorderColor('btnBorderColor');
//     }
//   }
//   /deep/.el-switch {
//     &.is-checked {
//       .el-switch__core {
//         @include setBgColor('contentSwitchActiveBgColor');
//         @include setBorderColor('contentSwitchActiveBgColor');
//       }
//     }
//     .el-switch__core {
//       @include setBgColor('contentSwitchBgColor');
//       @include setBorderColor('contentSwitchBgColor');
//     }
//   }
// }

.userinfo-more-wrapper {
  min-width: 200px;
  padding: 0 20px;
  box-sizing: border-box;
}

.userinfo-more-wrapper .hotline-num {
  margin: 0 10px;
  color: #7e7e7e;
}

.userinfo-more-wrapper > div,
.userinfo-more-wrapper > a {
  display: flex;
  align-items: center;
  padding: 16px 0;
  color: #000;
  border-bottom: 1px solid $--border-color-content;
  cursor: pointer;
  box-sizing: border-box;
}

.userinfo-more-wrapper > div:last-of-type {
  border: 0;
}

.userinfo-more-wrapper > div:hover {
  color: #ffa000;
}

.userinfo-more-wrapper > div:hover .hotline-num {
  color: #ffa000;
}

.userinfo-more-wrapper > div:hover .iconify {
  color: #ffa000;
}

.userinfo-more-wrapper .iconify {
  margin-right: 16px;
  color: #000;
  font-size: 20px;
}

.userinfo-more-wrapper .iconify.iconify--uiw {
  margin: 0 0 0 auto;
  color: #dfdfdf;
}

.userinfo-more-wrapper .card_box {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  margin: 20px 0;
  padding: 12px 16px;
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  cursor: default;
  transition: box-shadow 0.5s;
}

.is_darkblue {
  background-color: #012241;
}

.is_darkblue:hover {
  box-shadow: 0 0 10px #012241;
}

.is_green {
  background: linear-gradient(266.42deg, #009e63 0%, #037c4f 100%);
}

.is_green:hover {
  box-shadow: 0 0 10px #009e63;
}

.is_silvery {
  background: linear-gradient(266.42deg, #97a8bb 0%, #6d747a 100%);
}

.is_silvery:hover {
  box-shadow: 0 0 10px #97a8bb;
}

.is_gold {
  background: linear-gradient(266.42deg, #e3bd88 0%, #e3b173 100%);
}

.is_gold:hover {
  box-shadow: 0 0 10px #e3bd88;
}

.userinfo-more-wrapper .card_box div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #fff;
}

.card_box div:first-child {
  font-size: 15px;
}

.card_label {
  margin-right: 12px;
}

.release_history {
  span {
    position: relative;
    &.active::after {
      content: '';
      position: absolute;
      top: 0;
      right: -6px;
      width: 6px;
      height: 6px;
      background-color: red;
      border-radius: 50%;
    }
  }
}

.theme_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.float-box {
  display: flex;
  // position: absolute;
  width: 600px;
  height: 350px;
  // background-color: #fff;
  // z-index: 9999;
  // box-shadow: 0px 2px 10px #cfcfcf;
  border-radius: 3px;
  // transform: translateX(-45%);
  padding-top: 30px;
  box-sizing: border-box;
  @include setBgColor('topMenuBgColor');
}

/* .float-group:hover > .float-box, */
.float-btn:hover + .float-box {
  display: flex;
  justify-content: center;
}

.qr_img_group {
  margin: 0 50px;
}

.qr_img_group:first-child {
  margin-right: 0;
}

.qr_img {
  width: 200px;
  margin: 10px;
}

.qr_label {
  width: 196px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  border: 2px #8b8b8b solid;
  margin: 10px;
  color: #8b8b8b;
  background-color: #cfcfcf;
  font-weight: normal;
  text-align: center;
  font-size: 17px;
}

.qr_label i.iconfont {
  display: inline-block;
  color: #8b8b8b;
  font-size: 35px;
}

.qr_label_content {
  display: inline-block;
  vertical-align: top;
}

.release-dialog {
  .release-content-wrapper {
    p {
      padding-left: 3em;
      margin: 0 0 20px;
      text-indent: -1em;
      font-size: $--font-size-base;
      line-height: 1.8;
      color: $--color-text-primary;
    }
  }
  .button-wrapper {
    padding-top: 20px;
    border-top: 1px solid $--border-color-widget;
    text-align: center;
  }
}
</style>
